// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~gridstack/dist/gridstack.css";
@import "~gridstack/dist/gridstack-extra.css";

ion-modal:not([modal='saved-cart']) {
    --height: 100% !important;
    --width: 100% !important;

    @media screen and (min-width: 768px) {
        padding: 3em !important;
        --ion-background-color: none;
        --box-shadow: none !important;

        &::part(backdrop) {
            --backdrop-opacity: 0.7;
        }
    }
}

@keyframes blur-backdrop {
    0% {
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
    }

    100% {
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
    }
}

ion-button:hover {
    --opacity: 1;
}

button:disabled .alert-radio-label {
    opacity: 0.3;
    color: var(--ion-color-danger-tint);

    &:after {
        content: "Unavailable"
    }
}

ion-select.quantity-select {
    &:before {
        content: "Qty: "
    }
}

ion-toast {
    //Toast should always be on top right?
    z-index: 1000000000000;
}

h1 {
    color: var(--ion-title-colour);
}

.toast-wrapper {
    color: var(--ion-color-light);
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(30px);
    --webkit-backdrop-filter: blur(30px);
}

.info-alert .alert-wrapper {
    --min-width: 90%;

    @media screen and (min-device-width: 768px) {
        --min-width: 600px;
    }
}

ion-alert {
    align-items: start !important;
    padding-top: 60px;

    &.payment-alert {
        .alert-sub-title {
            margin-top: 15px;
            color: var(--ion-color-step-550, #737373);
        }

        .alert-message {
            padding-bottom: 0;
        }
    }
}

.grid-stack {

    .ui-resizable-handle {
        opacity: 0;
        min-height: 40px;
        min-width: 40px;
    }

    .ui-resizable-s {
        bottom: -30px !important;
    }
}

// Should be used on CTA's
.cursor-pointer {
    cursor: pointer;
}

// For indenting to move away from Chatra icon
.indent-title {
    margin-left: 5rem;
}

.price {
    color: var(--ion-title-colour);
}

.alert-failure .alert-input {
    animation-name: failureKeyFrames;
    animation-duration: 1s;
}

@keyframes failureKeyFrames {
    0% {
        border-bottom-color: var(--ion-color-medium)
    }

    50% {
        border-bottom-color: var(--ion-color-danger)
    }

    100% {
        border-bottom-color: var(--ion-color-medium)
    }
}

ion-alert .alert-radio-label {
    white-space: normal !important
}

[hidden] {
    display: none !important;
}

ion-loading.payment-loading .loading-content.sc-ion-loading-md {
    margin-inline-end: 50px;
}

.grid-stack>.grid-stack-item>.grid-stack-item-content {
    overflow-y: hidden;
}